"use client";
import classNames from "classnames";
import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import Image from "next/image";
import IconEyeHide from "@/components/Icon/IconEyeHide.svg";
import IconEyeShow from "@/components/Icon/IconEyeShow.svg";
const Input = forwardRef(
  (
    {
      className,
      value,
      placeholder,
      label,
      type,
      required,
      disabled = false,
      rightIcon,
      minLength,
      maxLength,
      onChange,
      ...restProps
    },
    ref
  ) => {
    const inputContainerRef = useRef(null);
    const inputRef = useRef(null);
    const [error, setError] = useState("");
    const [isFocus, setIsFocus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleChangeValue = e => {
      if (maxLength && e.target.value.length > maxLength) return;
      if (type === "number" && isNaN(e.target.value.replaceAll(" ", ""))) return;
      if (type === "phone") {
        if (e.target.value.length > 10) return;
        e.target.value = e.target.value.replace(/\D/g, "");
      }
      onChange(e.target.value);
    };

    const validate = () => {
      var validate = true;
      const phoneRegex = /^04\d{8}$/;
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!value && required) {
        setError(`This field is required`);
        validate = false;
      } else if (type === "email" && !regex.test(String(value).toLowerCase())) {
        setError(`Enter a valid email`);
        validate = false;
      } else if (type === "number" && isNaN(value)) {
        setError(`Enter a valid number`);
        validate = false;
      } else if (minLength && value.length < minLength) {
        setError(`Minimum ${minLength} numbers`);
        validate = false;
      } else if (type === "date" && !value) {
        setError(`Enter a valid date`);
        validate = false;
      } else if (type === "phone" && (value.length < 10 || !phoneRegex.test(value.replaceAll(" ", "")))) {
        setError(`Please enter your phone number in the format 0401 234 567`);
        validate = false;
      }
      // if (!validate) {
      //   inputContainerRef.current.scrollIntoView({ behavior: "smooth" });
      // }
      return validate;
    };
    const showErrorMessage = message => {
      if (message) setError(message);
    };
    useImperativeHandle(ref, () => ({
      validate,
      showErrorMessage,
    }));

    useEffect(() => {
      setError("");
    }, [value]);

    useEffect(() => {
      if (type === "password") {
        inputRef.current.type = showPassword ? "text" : "password";
      }
    }, [showPassword, type]);

    function handleClickOutside(event) {
      if (inputContainerRef.current && !inputContainerRef.current.contains(event.target)) {
        setIsFocus(false);
      } else {
        setIsFocus(true);
      }
    }
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    return (
      <div
        ref={inputContainerRef}
        className={classNames(
          "h-[48px] flex flex-col justify-center  rounded-sm relative",
          error ? "border-red border-[2px]" : "border-grey border-[1px]",
          disabled ? "bg-grey-200" : "bg-transparent"
        )}
      >
        {(isFocus || value || error) && !disabled && (
          <div className="flex justify-between px-4">
            {label && (
              <label className={classNames("text-xs", error ? "text-red" : "text-grey")}>
                {label}
                {required && "*"}
              </label>
            )}
            {error && (
              <span
                className={classNames("text-xs text-red", {
                  "mr-3": rightIcon || type === "password",
                })}
              >
                {error}
              </span>
            )}
          </div>
        )}
        <input
          className={classNames("w-full outline-none px-4 bg-transparent", {
            [className]: className,
          })}
          ref={inputRef}
          type={type === "number" ? "text" : type === "phone" ? "text" : type}
          placeholder={!isFocus && !error ? placeholder : ""}
          value={value}
          disabled={disabled}
          onChange={handleChangeValue}
          onClick={() => setIsFocus(true)}
          {...restProps}
        />
        {type === "password" && value && (
          <button
            type="button"
            className="absolute right-[11px] top-1/2 -translate-y-1/2"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <Image src={IconEyeHide.src} width="16" height="16" alt="IconEyeHide" />
            ) : (
              <Image src={IconEyeShow.src} width="16" height="16" alt="IconEyeShow" />
            )}
          </button>
        )}
        <div className="absolute right-[11px] top-1/2 -translate-y-1/2">{rightIcon}</div>
      </div>
    );
  }
);
Input.displayName = "Input";
export default Input;
