"use client";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import Input from "../Input";
import { getCookie } from "@/services/cookie";
import { useSignIn } from "@/lib/saleor/reactSaleor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KlaviyoEventKeys, createKlaviyoEvent } from "@/lib/klaviyo/events";

export default function LoginForm({}) {
  const router = useRouter();
  const inputRef = useRef();
  const [signIn] = useSignIn();

  const [keepMeLogged, setKeepMeLogged] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validate = () => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var validate = true;
    if (!email) {
      setEmailError("This field is required");
      validate = false;
    } else if (!regex.test(String(email).toLowerCase())) {
      setEmailError("Invalid email address");
      validate = false;
    } else {
      setEmailError("");
    }
    if (!password) {
      setPasswordError("This field is required");
      validate = false;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters");
      validate = false;
    } else {
      setPasswordError("");
    }
    return validate;
  };

  useEffect(() => {
    const token = getCookie("token");
    if (token) router.push("/");
  }, [router]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (validate()) {
      const { errors, data } = await signIn({
        email: email,
        password: password,
        keepMeLogged: keepMeLogged,
      });
      if (errors && errors.userInputErrors && errors.userInputErrors.length > 0) {
        if (!errors.userInputErrors[0].field) {
          toast.error("Login failed: " + errors.userInputErrors[0].message);
        }
      } else {
        createKlaviyoEvent(KlaviyoEventKeys.Login, email);
        toast.success("Login success. Hi " + email);
        router.push("/");
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <form className="flex flex-col font-roboto" onSubmit={handleSubmit}>
        <div className="w-full flex flex-col">
          <label className="h-[22px] text-sm leading-[22px]">Email Address</label>
          <Input
            ref={inputRef}
            className="text-sm"
            placeholder="Enter Your Email Address"
            type="text"
            value={email}
            onChange={data => {
              setEmail(data);
              if (!data) setEmailError("This field is required");
              else setEmailError("");
            }}
          />
          {emailError && <p className="text-white bg-red min-h-[21px] px-[10px] py-[5px] text-sm">{emailError}</p>}
        </div>
        <div className="w-full flex flex-col mt-7">
          <label className="h-[22px] text-sm leading-[22px]">Password</label>
          <Input
            ref={inputRef}
            className="text-sm"
            placeholder="Enter Password"
            type="password"
            value={password}
            onChange={data => {
              setPassword(data);
              if (!data) setPasswordError("This field is required");
              else setPasswordError("");
            }}
          />
          {passwordError && (
            <p className="text-white bg-red min-h-[21px] px-[10px] py-[5px] text-sm">{passwordError}</p>
          )}
        </div>

        <div className="w-full flex flex-col mt-4">
          <div className="flex h-5 leading-5 items-center">
            <input
              checked={keepMeLogged}
              onChange={() => setKeepMeLogged(!keepMeLogged)}
              name="logged"
              type="checkbox"
              id="validation"
              className="form-check-input"
            />
            <label title="" htmlFor="validation" className="ml-2 text-sm leading-[22px]">
              Keep me logged in
            </label>
          </div>
        </div>
        <div className="w-full mt-5 mx-auto">
          <button
            type="submit"
            className="w-full rounded-sm leading-[21px] h-[64px] ,x-auto font-rajdhani text-center font-bold bg-gold"
          >
            Login
          </button>
        </div>
      </form>
      <div className="mt-3 px-4 w-fit mx-auto">
        <Link href="/profile/forgot-password" className="text-xs leading-5 text-grey underline">
          Reset Password
        </Link>
      </div>
      <div className="mt-6 w-full mx-auto">
        <Link href="/create-account">
          <button type="button" className="w-full h-[64px] border-[1px] border-black font-rajdhani text-sm font-bold">
            CREATE ACCOUNT
          </button>
        </Link>
      </div>
    </>
  );
}
