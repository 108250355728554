const SIZE_ATTRS = ["Sizes", "Size Charts", "Casual Sizes 10XS-10XL", "Helmet Sizing", "Helmet Sizing 2"];

export const KlaviyoEventKeys = {
  Login: "Login",
  Signup: "Signup",
  ViewProduct: "View Product",
  SelectVariant: "Select Variant",
  AddToCart: "Add To Cart",
  ViewCart: "View Cart",
  BeginCheckout: "Begin Checkout",
  CompleteCheckout: "Complete Checkout",
  CompleteTransaction: "Complete Transaction",
};

const mapLeadTime = inventories => {
  const inStockEnvs = inventories?.find(item => item.ownerType == "warehouse" && item.inventoryCount > 0);

  const isValidStock = inStockEnvs && inStockEnvs.inventoryCount > 0;
  const onDemandEnvs = inventories?.find(item => item.ownerType == "supplier");

  return isValidStock ? inStockEnvs.leadTime + 1 : (onDemandEnvs?.leadTime || 0) + 1;
};

const mappedVariant = (variant, product) => {
  if (!variant) return {};
  const variantData = {
    ...variant,
    imageUrl: product?.thumbnail?.url || "",
    price: {
      retailPrice: variant?.price?.retail_price || "0.00",
      rrpPrice: variant?.price?.rrp_price || "0.00",
      specialPrice: variant?.price?.special_price ? parseFloat(variant.price.special_price).toFixed(2) : "0.00",
    },
    size:
      ((variant?.attributes || []).find(attr => SIZE_ATTRS.includes(attr?.attribute?.name))?.values || [])[0]?.value ||
      "",
    color: ((variant?.attributes || []).find(attr => attr?.attribute?.name == "Colour")?.values || [])[0]?.value || "",
  };

  if (variant?.inventories?.length > 0) {
    variantData.leadTime = mapLeadTime(variant.inventories);
  }

  delete variantData?.pricing;
  delete variantData?.attributes;
  delete variantData?.variantAttributeValues;
  delete variantData?.inventories;
  return variantData;
};
export const setSelectVariantKlaviyoEvent = (product, variant) => {
  if (!variant) return;
  const user = localStorage.getItem("user_metadata");
  const userEmail = JSON.parse(user)?.email;
  if (product?.name && product?.virtualSku) {
    createKlaviyoEvent(KlaviyoEventKeys.SelectVariant, userEmail, {
      sku: product.virtualSku,
      name: product.name,
      productUrl: product.slug ? `${process.env.NEXT_PUBLIC_URL}/products/${product.slug}` : "",
      variantDetail: {
        ...mappedVariant(variant, product),
      },
    });
  }
};

export const setAddToCartKlaviyoEvent = async (userEmail, product, variant) => {
  // if (!userEmail) return;

  let properties = {
    sku: product?.virtualSku || "",
    name: product?.name || "",
  };

  if (variant) {
    properties = {
      ...properties,
      variantDetail: {
        ...mappedVariant(variant, product),
      },
    };
  }

  if (!product?.productType?.hasVariants) {
    properties = {
      ...properties,
      isAvailable: product.isAvailable,
      price: {
        specialPrice: product.pricing.onSale
          ? parseFloat(
              product?.pricing?.priceRange?.start?.gross?.amount
                ? product.pricing.priceRange.start.gross.amount
                : "0.00"
            ).toFixed(2)
          : "0.00",
        retailPrice: product?.pricing?.priceRangeUndiscounted?.start?.gross?.amount
          ? parseFloat(product.pricing.priceRangeUndiscounted.start.gross.amount).toFixed(2)
          : "0.00",
        rrpPrice: product?.pricing?.priceRangeUndiscounted?.start?.gross?.amount
          ? parseFloat(product.pricing.priceRangeUndiscounted.start.gross.amount).toFixed(2)
          : "0.00",
      },
      productUrl: `${process.env.NEXT_PUBLIC_URL}/products/${product.slug}`,
      imageUrl: product?.thumbnail?.url || "",
    };
    if (product?.metadata?.length > 0) {
      const value = JSON.parse(product.metadata[0].value);
      if (value?.inventories?.length > 0) {
        properties.leadTime = mapLeadTime(value.inventories);
      }
    }
  }

  await createKlaviyoEvent(KlaviyoEventKeys.AddToCart, userEmail, properties);
};

const extractCartLine = rawLine => {
  let variant = rawLine?.variant;
  let product = variant?.product;
  return {
    name: product?.name || "",
    sku: variant?.sku || product?.virtualSku || "",
    size:
      ((variant?.attributes || []).find(attr => SIZE_ATTRS.includes(attr?.attribute?.name))?.values || [])[0]?.value ||
      "",

    color: ((variant?.attributes || []).find(attr => attr?.attribute?.name == "Colour")?.values || [])[0]?.value || "",

    imageUrl: product?.thumbnail?.url || "",
    productUrl: product?.slug ? `${process.env.NEXT_PUBLIC_URL}/products/${product?.slug}` : "",
    leadTime: "",
    vehicleId: "",
  };
};

const extractCartLines = rawLines => {
  return rawLines.map(line => extractCartLine(line));
};

const extractCartData = rawData => {
  return {
    lines: extractCartLines(rawData?.lines),
    checkoutUrl: `${process.env.NEXT_PUBLIC_URL}/checkout/delivery`,
  };
};

export const setBeginCheckoutKlaviyoEvent = async (userEmail, rawData) => {
  // if (!userEmail) return;
  if ((rawData?.lines || []).length === 0) return;
  const localCart = JSON.parse(localStorage.getItem("guest_data_checkout_tmp") || "{}");

  const cartData = extractCartData(rawData);

  if (localCart?.lines?.length > 0) {
    localCart.lines.forEach(line => {
      const rawLine = extractCartLine(line);
      const index = cartData.lines.findIndex(item => item.sku === rawLine.sku && item.size === rawLine.size);
      if (index === -1) {
        cartData.lines.push(rawLine);
      }
    });
  }
  createKlaviyoEvent(KlaviyoEventKeys.BeginCheckout, userEmail, {
    cartData,
  });
  localStorage.removeItem("guest_data_checkout_tmp");
};

let tokens = [];
export const setCompleteCheckoutKlaviyoEvent = async (userEmail, token, rawData) => {
  if (tokens.includes(token)) return; // avoid dup tracking
  tokens.push(token);
  // if (!userEmail) return;
  if ((rawData?.lines || []).length === 0) return;
  const cartData = extractCartData(rawData);
  createKlaviyoEvent(KlaviyoEventKeys.CompleteCheckout, userEmail, {
    cartData,
  });
};

export const createKlaviyoEvent = (eventName, customerEmail, properties = {}) => {
  if (typeof klaviyo === "undefined") return;

  if (process.env.NEXT_PUBLIC_ENV !== "production") {
    console.info("createKlaviyoEvent", {
      eventName,
      customerEmail,
      properties,
    });
  }

  if (customerEmail) {
    klaviyo.identify({ $email: `${customerEmail}` }, () => {
      klaviyo.push(["track", eventName, properties]);
    });
  } else {
    klaviyo.push(["track", eventName, properties]);
  }
};
